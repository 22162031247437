import React, { FC, useState, useEffect } from 'react';
import { UserFeatures } from '.';
import { userFeaturesDefault } from './personalization.interface';

export type PersonalizationContext = {
  isPreview: boolean;
  setIsPreview: React.Dispatch<React.SetStateAction<boolean>>;
  previewData: UserFeatures;
  setPreviewData: React.Dispatch<React.SetStateAction<UserFeatures | null>>;
};

const defaultState = {
  isPreview: false,
  setIsPreview: console.log,
  previewData: userFeaturesDefault,
  setPreviewData: console.log,
};

export const PersonalizationStateContext = React.createContext<PersonalizationContext>(
  defaultState
);
export const PersonalizationProvider = PersonalizationStateContext.Provider;
export const PersonalizationContextConsumer = PersonalizationStateContext.Consumer;

const PersonalizationContextProvider: FC<{}> = ({ children }) => {
  const [personalizationState, setPersonalizationState] = useState({
    ...defaultState,
  });
  const [previewData, setPreviewData] = React.useState<UserFeatures>(defaultState.previewData);
  const [isPreview, setIsPreview] = React.useState<boolean>(defaultState.isPreview);

  useEffect(() => {
    setPersonalizationState({
      isPreview,
      setIsPreview,
      previewData,
      setPreviewData,
    });
  }, [isPreview, previewData]);

  if (personalizationState === defaultState) {
    return <>{children}</>;
  }

  return <PersonalizationProvider value={personalizationState}>{children}</PersonalizationProvider>;
};

export default PersonalizationContextProvider;
