import { RelatedBlade } from '../../blades';

export interface User extends UserProfile, UserFeatures {}

export interface UserFeatures {
  is_customer: boolean;
  bot_management_enabled: boolean;
  argo_allow_smart_routing: boolean;
  has_free_zone: boolean;
  has_pro_zone: boolean;
  has_biz_zone: boolean;
  has_ent_zone: boolean;
  ctm_enabled: boolean;
  workers_enabled: boolean;
  audit_logs_enabled: boolean;
  cf_registrar_enabled: boolean;
  proxy_anything_enabled: boolean;
  access_enabled: boolean;
  rate_limiting_enabled: boolean;
  stream_enabled: boolean;
  logshare_enabled: boolean;
  organization_name: string;
  pct_avg_change: number | null;
  argo_avg: number | null;
  no_argo_avg: number | null;
}

export const userFeaturesDefault: UserFeatures = {
  is_customer: false,
  bot_management_enabled: false,
  argo_allow_smart_routing: false,
  has_free_zone: false,
  has_pro_zone: false,
  has_biz_zone: false,
  has_ent_zone: false,
  ctm_enabled: false,
  workers_enabled: false,
  audit_logs_enabled: false,
  cf_registrar_enabled: false,
  proxy_anything_enabled: false,
  access_enabled: false,
  rate_limiting_enabled: false,
  stream_enabled: false,
  logshare_enabled: false,
  organization_name: '',
  pct_avg_change: null,
  argo_avg: null,
  no_argo_avg: null,
};

export interface UserProfile {
  created: string;
  last_login: string;
  device_id: string;
  id: string;
}
export interface MRKAudienceSegmentFact {
  contentTypeId: 'mrkAudienceSegmentFact';
  contentfulId: string;
  entryTitle: string;
  fact: keyof UserFeatures;
  value: string;
}

export interface MRKAudienceSegment {
  contentTypeId: 'mrkAudienceSegment';
  contentfulId: string;
  entryTitle: string;
  segmentName: string;
  facts: MRKAudienceSegmentFact[];
}

export interface MRKAudienceSegmentEntry {
  contentTypeId: 'mrkAudienceSegmentEntry';
  contentfulId: string;
  entryTitle: string;
  audienceSegment: MRKAudienceSegment;
  segmentEntry: RelatedBlade;
}

export interface ContentfulPersonalizer {
  contentTypeId: 'mrkPersonalizer';
  contentfulId: string;
  entryTitle: string;
  personalisedContentName: string;
  personalisedEntries: MRKAudienceSegmentEntry[];
  controlEntry: RelatedBlade;
}
