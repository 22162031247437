import React, { useContext } from 'react';
import { PersonalizationStateContext } from '../../../contentful/content-types/personalization/personalization.context';
import { Div } from '../Elements';
import { Icon } from '../Icon';

export default () => {
  const { isPreview, setIsPreview } = useContext(PersonalizationStateContext);
  return (
    <Div
      onClick={() => setIsPreview(!isPreview)}
      role="button"
      padding={0}
      paddingHorizontal={2}
      border="all"
      borderColor="black-025"
      style={{
        borderRadius: '2px',
        cursor: 'pointer',
        transition: '200ms opacity ease',
        right: '24px',
        bottom: '24px',
        height: '60px',
        width: '60px',
        overflow: 'hidden',
        boxShadow: ' 0 12px 48px 4px rgb(0 0 0 / 12%)',
      }}
      position="fixed"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="orange1"
      color="white"
      zIndex={2}
      fontWeight="bold"
    >
      <Icon type="cog" />
    </Div>
  );
};
